import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import {
    VIDEO_CALL_MICROPHONE_STATUS,
    VIDEO_CALL_SCREEN_MODE,
    VideoCallMicrophoneStatus,
    VideoCallScreenMode,
} from '../../utils/video-call-dialog-config';
import { UiVideoCallsActions } from '../actions/uiVideoCall';
import {
    AlertResolutionPageActions,
    InstallationPageActions,
    VideoCallsActions,
    VideoCallsNotificationActions,
} from '../actions/videoCall';

export type UiVideoCallsState = {
    screenMode: VideoCallScreenMode;
    microphone: VideoCallMicrophoneStatus;
    siteId: SiteId;
};

export const UI_VIDEO_CALLS_INITIAL_STATE: UiVideoCallsState = {
    screenMode: VIDEO_CALL_SCREEN_MODE.FULLSCREEN,
    microphone: VIDEO_CALL_MICROPHONE_STATUS.ON,
    siteId: null,
};

export default createReducer(
    UI_VIDEO_CALLS_INITIAL_STATE,

    on(UiVideoCallsActions.videoCallScreenModeToggled, (state, { screenMode }): UiVideoCallsState => {
        if (state.screenMode === screenMode) {
            return state;
        }
        return {
            ...state,
            screenMode,
        };
    }),

    on(UiVideoCallsActions.turnedOnMicrophone, (state): UiVideoCallsState => {
        return {
            ...state,
            microphone: VIDEO_CALL_MICROPHONE_STATUS.ON,
        };
    }),

    on(UiVideoCallsActions.turnedOffMicrophone, (state): UiVideoCallsState => {
        return {
            ...state,
            microphone: VIDEO_CALL_MICROPHONE_STATUS.OFF,
        };
    }),

    on(
        VideoCallsNotificationActions.videoCallJoined,
        InstallationPageActions.videoCallJoined,
        AlertResolutionPageActions.videoCallJoined,
        (state, { siteId }): UiVideoCallsState => {
            return {
                ...state,
                siteId,
            };
        },
    ),

    on(VideoCallsActions.videoCallStoppedRequested, VideoCallsActions.videoCallStopped, (state): UiVideoCallsState => {
        return {
            ...state,
            screenMode: VIDEO_CALL_SCREEN_MODE.FULLSCREEN,
            siteId: null,
        };
    }),
);

export const getUiVideoCallsState = createFeatureSelector<UiVideoCallsState>('ui-video-calls');
