import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { VideoCallScreenMode } from '../../utils/video-call-dialog-config';

export const UiVideoCallsActions = createActionGroup({
    source: 'UIVideoCalls',
    events: {
        videoCallScreenModeToggled: props<{ screenMode: VideoCallScreenMode }>(),
        turnedOnMicrophone: emptyProps(),
        turnedOffMicrophone: emptyProps(),
    },
});
