import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { InterventionsApi } from '../../../interventions/store/api/interventions';
import { AppState } from '../../../shared/store/app-state';
import { Site, SiteId } from '../../models/Site';
import { DeviceService } from '../../services/device.service';
import { SiteService } from '../../services/site.service';
import { SiteDevicesActions } from '../actions/devices';
import { loadSiteInfo } from '../actions/site';
import { getSiteById } from '../selectors/site';

@Injectable()
export class SiteDetailApi {
    constructor(
        private store: Store<AppState>,
        private siteService: SiteService,
        private deviceService: DeviceService,
        private interventionsApi: InterventionsApi,
    ) {}

    loadSite(siteId: SiteId, withDeviceSynchro = false): Observable<any> {
        return this.loadSiteInfo(siteId).pipe(
            mergeMap((site) => {
                return forkJoin([
                    this.loadSiteDevices(site.id, withDeviceSynchro),
                    this.interventionsApi.loadSiteInterventions(site.id),
                ]);
            }),
        );
    }

    loadSiteInfo(siteId: SiteId): Observable<Site> {
        let siteFound: Site;
        this.store
            .select(getSiteById(siteId))
            .pipe(take(1))
            .subscribe((value) => (siteFound = value));
        if (siteFound) {
            const siteFoundCopy = Object.assign({}, siteFound);
            return of(siteFoundCopy);
        }

        return this.siteService.getSite(siteId).pipe(
            map((site) => {
                this.store.dispatch(loadSiteInfo({ site }));
                return site;
            }),
        );
    }

    loadSiteDevices(siteId: SiteId, withSynchro = false): Observable<any> {
        return this.deviceService.getSiteDevices(siteId, withSynchro).pipe(
            map((devices) => {
                this.store.dispatch(SiteDevicesActions.devicesLoaded({ siteId, devices }));
                return devices;
            }),
        );
    }
}
