import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, map, Observable } from 'rxjs';
import { AppState } from '../../../shared/store/app-state';
import { UiVideoCallsActions } from '../../store/actions/uiVideoCall';
import { getVideoCallMicrophone, getVideoCallScreenMode } from '../../store/selectors/ui-video-call';
import {
    VIDEO_CALL_MICROPHONE_STATUS,
    VideoCallMicrophoneStatus,
    VideoCallScreenMode,
} from '../../utils/video-call-dialog-config';

interface Data {
    microphone: VideoCallMicrophoneStatus;
    screenMode: VideoCallScreenMode;
}

@Component({
    selector: 'app-video-call-microphone-button',
    standalone: true,
    imports: [AsyncPipe, TranslateModule],
    templateUrl: './video-call-microphone-button.component.html',
    styleUrl: './video-call-microphone-button.component.scss',
})
export class VideoCallMicrophoneButtonComponent implements OnInit {
    microphone$: Observable<VideoCallMicrophoneStatus>;
    screenMode$: Observable<VideoCallScreenMode>;

    data$: Observable<Data>;

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.microphone$ = this.store.select(getVideoCallMicrophone);
        this.screenMode$ = this.store.select(getVideoCallScreenMode);

        this.data$ = combineLatest([this.microphone$, this.screenMode$]).pipe(
            map(([microphone, screenMode]) => ({ microphone, screenMode })),
        );
    }

    toggleMicrophoneClick(microphoneStatus: VideoCallMicrophoneStatus): void {
        const action =
            microphoneStatus === VIDEO_CALL_MICROPHONE_STATUS.ON
                ? UiVideoCallsActions.turnedOffMicrophone()
                : UiVideoCallsActions.turnedOnMicrophone();
        this.store.dispatch(action);
    }
}
