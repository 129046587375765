import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationComponent } from './core/components/authentication/authentication.component';
import { HeaderComponent } from './core/components/header/header.component';
import { OnboardingComponent } from './core/components/onboarding/onboarding.component';
import { UnAuthorizedComponent } from './core/components/unauthorized/unauthorized.component';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { OnboardingGuard } from './shared/guards/onboarding.guard';

export const appRoutes: Routes = [
    {
        path: '',
        component: HeaderComponent,
        children: [
            { path: '', redirectTo: 'sites', pathMatch: 'full' },
            { path: 'auth', component: AuthenticationComponent, canActivate: [AuthGuard] },
            {
                path: '',
                component: DashboardComponent,
                children: [
                    {
                        path: 'sites',
                        loadComponent: () =>
                            import('./site-list/components/site-list/site-list.component').then(
                                (mod) => mod.SiteListComponent,
                            ),
                        canActivate: [AuthGuard, OnboardingGuard],
                    },
                    {
                        path: 'sites-pending-activation',
                        loadComponent: () =>
                            import(
                                './site-assignation/components/site-assignation-list/site-assignation-list.component'
                            ).then((mod) => mod.SiteAssignationListComponent),
                        canActivate: [AuthGuard, OnboardingGuard],
                    },
                    {
                        path: 'alerts',
                        loadComponent: () =>
                            import('./alerts/components/alert-list/alert-list.component').then(
                                (mod) => mod.AlertListComponent,
                            ),
                        canActivate: [AuthGuard, OnboardingGuard],
                    },
                ],
            },
            {
                path: 'resolve-alert',
                loadChildren: () => import('./alert-resolution/alert-resolution.routes').then((mod) => mod.routes),
                canActivate: [AuthGuard, OnboardingGuard],
            },
            {
                path: 'messages',
                loadChildren: () => import('./messages/messages.routes').then((mod) => mod.routes),
                canActivate: [AuthGuard, OnboardingGuard],
            },
            {
                path: 'alerts/config',
                loadChildren: () =>
                    import('./alerts-notification-config/alerts-notification-config.routes').then((mod) => mod.routes),
                canActivate: [AuthGuard, OnboardingGuard],
            },
            {
                path: 'site',
                loadChildren: () => import('./site-detail/site-details.routes').then((mod) => mod.routes),
                canActivate: [AuthGuard, OnboardingGuard],
            },
        ],
    },
    {
        path: 'query',
        loadChildren: () => import('./query/query.routes').then((mod) => mod.routes),
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.routes').then((mod) => mod.routes),
    },
    {
        path: 'portfolio',
        loadChildren: () => import('./portfolio/portfolio.routes').then((mod) => mod.routes),
    },
    { path: 'unauthorized', component: UnAuthorizedComponent },
    {
        path: 'onboarding',
        component: OnboardingComponent,
        canActivate: [AuthGuard],
    },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, { useHash: false });
