import { createSelector } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { VIDEO_CALL_STATUS, VideoCall } from '../../models/video-call';
import { getVideoCallsState, VideoCallsState } from '../reducers/video-call';
import { getVideoCallSiteId } from './ui-video-call';

export const getVideoCalls = createSelector(getVideoCallsState, (videoCalls: VideoCallsState) => videoCalls);

export const getVideoCallBySiteId = (siteId: SiteId) =>
    createSelector(getVideoCalls, (videoCalls): VideoCall => {
        return videoCalls[siteId];
    });

export type VideoCallButtonStateView =
    | {
          action: 'APPROVE_SEND_INVITATION' | 'NONE';
          label: string;
      }
    | {
          action: 'RESEND_INVITATION' | 'JOIN_VIDEO_CALL';
          label: string;
          videoCall: VideoCall;
      }
    | {
          action: 'STOP_AND_JOIN_VIDEO_CALL';
          label: string;
          videoCall: VideoCall;
          currentVideoCallSiteId: SiteId;
      };

export const getVideoCallButtonState = (siteId: SiteId) =>
    createSelector(
        getVideoCallBySiteId(siteId),
        getVideoCallSiteId,
        (videoCall: VideoCall, currentVideoCallSiteId: SiteId): VideoCallButtonStateView => {
            if (videoCall?.status === VIDEO_CALL_STATUS.IN_PROGRESS) {
                return {
                    action: 'NONE',
                    label: 'VIDEO_CALL_IN_PROGRESS',
                };
            }

            if (videoCall?.status === VIDEO_CALL_STATUS.AVAILABLE) {
                if (currentVideoCallSiteId !== null && currentVideoCallSiteId !== videoCall.siteId) {
                    return {
                        action: 'STOP_AND_JOIN_VIDEO_CALL',
                        label: 'VIDEO_CALL_END_AND_JOIN_AVAILABLE',
                        currentVideoCallSiteId,
                        videoCall,
                    };
                }

                return {
                    action: 'JOIN_VIDEO_CALL',
                    label: 'VIDEO_CALL_AVAILABLE_TO_JOIN',
                    videoCall,
                };
            }

            if (videoCall?.status === VIDEO_CALL_STATUS.INVITATION_SENT) {
                return {
                    action: 'RESEND_INVITATION',
                    label: 'VIDEO_CALL_INVITATION_SENT',
                    videoCall,
                };
            }

            return {
                action: 'APPROVE_SEND_INVITATION',
                label: 'VIDEO_CALL_REQUEST',
            };
        },
    );
