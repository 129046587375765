import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../shared/store/app-state';
import { SiteId } from '../../site-detail/models/Site';
import { removeToasterMessage } from '../../site-detail/store/actions/ui';
import { VideoCall } from '../models/video-call';
import { getVideoCallToasterId } from '../utils/getVideoCallToasterId';
import { StopVideoCallUseCase } from './StopVideoCallUseCase';
import { VideoCallModal } from './video-call-modal';
import { VideoCallProvider } from './VideoCallProvider';

@Injectable()
export class VideoCallService {
    constructor(
        @Inject('VideoCallProvider') private videoCallProvider: VideoCallProvider,
        private stopVideoCallUseCase: StopVideoCallUseCase,
        private videoCallModal: VideoCallModal,
        private readonly store: Store<AppState>,
    ) {}

    async join({ siteId, url, ownerToken }: VideoCall) {
        this.store.dispatch(removeToasterMessage({ id: getVideoCallToasterId(siteId) }));
        this.videoCallModal.open({ siteId, url, ownerToken });
    }

    async stopAndJoin({ siteId, url, ownerToken }: VideoCall, stoppedSiteId: SiteId) {
        await this.videoCallProvider.leaveVideoCall();
        await this.stopVideoCallUseCase.exec(stoppedSiteId);
        this.videoCallModal.close();
        this.store.dispatch(removeToasterMessage({ id: getVideoCallToasterId(siteId) }));
        this.videoCallModal.open({ siteId, url, ownerToken });
    }
}
