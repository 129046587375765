import { createSelector } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { VideoCallMicrophoneStatus, VideoCallScreenMode } from '../../utils/video-call-dialog-config';
import { getUiVideoCallsState } from '../reducers/ui-video-call';

export const getVideoCallScreenMode = createSelector(
    getUiVideoCallsState,
    (uiVideoCallsState): VideoCallScreenMode => uiVideoCallsState.screenMode,
);

export const getVideoCallMicrophone = createSelector(
    getUiVideoCallsState,
    (uiVideoCallsState): VideoCallMicrophoneStatus => uiVideoCallsState.microphone,
);

export const getVideoCallSiteId = createSelector(
    getUiVideoCallsState,
    (uiVideoCallsState): SiteId => uiVideoCallsState.siteId,
);
