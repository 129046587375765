import { createActionGroup, props } from '@ngrx/store';
import { SiteId } from '../../../site-detail/models/Site';
import { VideoCall, VideoCallId } from '../../models/video-call';

export const VideoCallsActions = createActionGroup({
    source: 'VideoCalls',
    events: {
        videoCallsLoaded: props<{ videoCalls: VideoCall[] }>(),
        videoCallCreated: props<{ siteId: SiteId; videoCall: VideoCall }>(),
        sendVideoCallInvitationRequested: props<{ videoCallId: VideoCallId; siteId: SiteId }>(),
        videoCallInvitationSent: props<{ siteId: SiteId; videoCall: VideoCall }>(),
        videoCallStoppedRequested: props<{ siteId: SiteId }>(),
        videoCallStopped: props<{ siteId: SiteId }>(),
        videoCallParticipantLeft: props<{ siteId: SiteId; isOwner: boolean }>(),
        videoCallParticipantJoined: props<{
            siteId: SiteId;
            url: string;
            ownerToken: string;
            userName: string;
            isOwner: boolean;
        }>(),
    },
});

const events = {
    videoCallJoined: props<{ siteId: SiteId }>(),
    videoCallStoppedAndJoined: props<{ siteId: SiteId }>(),
};

export const VideoCallsNotificationActions = createActionGroup({
    source: 'VideoCalls Notification',
    events: events,
});

export const InstallationPageActions = createActionGroup({
    source: 'Installation Page',
    events: {
        startVideoCallRequested: props<{ siteId: SiteId }>(),
        ...events,
    },
});

export const AlertResolutionPageActions = createActionGroup({
    source: 'Alert Resolution Page',
    events: {
        startVideoCallRequested: props<{ siteId: SiteId }>(),
        ...events,
    },
});
