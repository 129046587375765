import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ToasterJoinVideoCallMessageContent } from '../../../shared/models/Toaster';
import { AppState } from '../../../shared/store/app-state';
import { removeToasterMessage } from '../../../site-detail/store/actions/ui';
import { VideoCallService } from '../../services/VideoCallService';
import { VideoCallsNotificationActions } from '../../store/actions/videoCall';
import { getVideoCallButtonState, VideoCallButtonStateView } from '../../store/selectors/video-call';

@Component({
    selector: 'app-video-call-toaster-item',
    standalone: true,
    imports: [TranslateModule, AsyncPipe],
    templateUrl: './video-call-toaster-item.component.html',
    styleUrl: './video-call-toaster-item.component.scss',
})
export class VideoCallToasterItemComponent implements OnInit {
    @Input() content: ToasterJoinVideoCallMessageContent;
    @Input() messageId: string;

    data$: Observable<VideoCallButtonStateView>;

    constructor(
        private readonly store: Store<AppState>,
        private readonly videoCallService: VideoCallService,
    ) {}

    ngOnInit(): void {
        this.data$ = this.store.select(getVideoCallButtonState(this.content.siteId));
    }

    protected onClose() {
        this.store.dispatch(removeToasterMessage({ id: this.messageId }));
    }

    protected async handleVideoCallAction(data: VideoCallButtonStateView): Promise<void> {
        if (data.action === 'JOIN_VIDEO_CALL') {
            await this.videoCallService.join(data.videoCall);
            this.store.dispatch(VideoCallsNotificationActions.videoCallJoined({ siteId: this.content.siteId }));
            this.onClose();
            return;
        }

        if (data.action === 'STOP_AND_JOIN_VIDEO_CALL') {
            await this.videoCallService.stopAndJoin(data.videoCall, data.currentVideoCallSiteId);
            this.store.dispatch(
                VideoCallsNotificationActions.videoCallStoppedAndJoined({ siteId: this.content.siteId }),
            );
            this.onClose();
        }
    }
}
