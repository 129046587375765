export const VIDEO_CALL_SCREEN_MODE = {
    FULLSCREEN: 'fullscreen',
    MINIMIZED: 'minimized',
} as const;

export type VideoCallScreenMode = (typeof VIDEO_CALL_SCREEN_MODE)[keyof typeof VIDEO_CALL_SCREEN_MODE];

export interface VideoCallDialogConfig {
    size: { width: string; height: string };
    position: { top?: string; bottom?: string; left?: string; right?: string };
}

export const DIALOG_CONFIG: Record<VideoCallScreenMode, VideoCallDialogConfig> = {
    fullscreen: {
        size: { width: '95vw', height: '95vh' },
        position: { bottom: '2.5vh', right: '2.5vw' },
    },
    minimized: {
        size: { width: '257px', height: '450px' },
        position: { bottom: '2.5vh', right: '2.5vw' },
    },
};

export const TOGGLE_SCREEN_MODE = {
    [VIDEO_CALL_SCREEN_MODE.FULLSCREEN]: VIDEO_CALL_SCREEN_MODE.MINIMIZED,
    [VIDEO_CALL_SCREEN_MODE.MINIMIZED]: VIDEO_CALL_SCREEN_MODE.FULLSCREEN,
};

export const VIDEO_CALL_MICROPHONE_STATUS = {
    ON: 'on',
    OFF: 'off',
};

export type VideoCallMicrophoneStatus =
    (typeof VIDEO_CALL_MICROPHONE_STATUS)[keyof typeof VIDEO_CALL_MICROPHONE_STATUS];
