import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ScreenSizeOrientationLayerComponent } from './core/components/screen-size-orientation-layer/screen-size-orientation-layer.component';
import { OauthService } from './core/services/oauth.service';
import { AppActions } from './core/store/actions/app';
import constants from './shared/const';
import { LoaderComponent } from './shared/loader/loader.component';
import { AnalyticsService } from './shared/services/analytics.service';
import { LocalStorageService } from './shared/services/localStorage.service';
import { UserEnvApi } from './shared/store/api/user-env';
import { setDefaultLocale } from './utils/date';
import { DEFAULT_LOCALE } from './utils/url';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [LoaderComponent, RouterOutlet, ScreenSizeOrientationLayerComponent],
})
export class AppComponent implements OnInit, OnDestroy {
    loading = false;

    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private translateService: TranslateService,
        private localStorageService: LocalStorageService,
        private router: Router,
        private analytics: AnalyticsService,
        private oauthService: OauthService,
        private userEnvApi: UserEnvApi,
        private store: Store,
    ) {
        // reset "bypass onboarding" in case the close the browser / tab without logout
        this.localStorageService.deleteItem(constants.LOCAL_STORAGE_BYPASS_ONBOARDING);

        this.translateService.setDefaultLang(DEFAULT_LOCALE);

        const locale =
            localStorageService.getItem('locale')?.toLowerCase() ||
            this.translateService.getBrowserLang().split('-')[0];

        this.translateService.use(locale);
        setDefaultLocale(locale);
    }

    loadEndUserEnvironment() {
        // i choose to use window.location rather than Angular's Router or ActivatedRoute
        // because this code seems to happen before redirection => router nor activatedRoute have '/admin' in their path/url/route
        const pathname = window.location.pathname;
        if (pathname.startsWith('/admin') || pathname.startsWith('/query') || pathname.startsWith('/portfolio')) {
            // if we are in the backoffice, we will login with the form
            // if we are in /query, there is no auth required
            return;
        }

        this.loading = true;
        if (!this.oauthService.isAuthenticated()) {
            this.oauthService
                .waitForAuthentication()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe(() => {
                    this.initEnvironment();
                });
        } else {
            this.initEnvironment();
        }
    }

    ngOnInit() {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart && /^\/#/.test(event.url)))
            .subscribe((event: NavigationStart) => this.router.navigateByUrl(event.url.replace('/#', '')));

        this.routeEvent(this.router);
        this.loadEndUserEnvironment();
    }

    private routeEvent(router: Router) {
        router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((e) => {
            if (e instanceof NavigationEnd) {
                this.analytics.notifyNavigation(e);
            }
        });
    }

    private initEnvironment() {
        this.store.dispatch(AppActions.initialized());

        this.userEnvApi
            .loadUserEnvironment()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => (this.loading = false));
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
