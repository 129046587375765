import { Intervention } from '../../interventions/models/Intervention';
import { SiteId } from '../../site-detail/models/Site';

export enum ToasterMessageType {
    DEVICE_UPDATE_STATE = 'DEVICE_UPDATE_STATE',
    DEVICE_REFRESH_STATE_FAILED = 'DEVICE_REFRESH_STATE_FAILED',
    ACCESS_GRANTED = 'ACCESS_GRANTED',
    SERVEGO_ACCESS_CHANGED = 'SERVEGO_ACCESS_CHANGED',
    SERVEGO_REQUEST_SENT = 'SERVEGO_REQUEST_SENT',
    SERVEGO_DELETE_SITE_INSTALLER_SENT = 'SERVEGO_DELETE_SITE_INSTALLER_SENT',
    INTERVENTION_REQUEST_SENT = 'INTERVENTION_REQUEST_SENT',
    SITE_DEVICE_REQUEST_ERROR = 'SITE_DEVICE_REQUEST_ERROR',
    ERROR = 'ERROR',
    CONFIRM_SUCCESS = 'CONFIRM_SUCCESS',
    INSTALLER_RECEIVE_NEW_MESSAGE = 'INSTALLER_RECEIVE_NEW_MESSAGE',
    GUEST_JOINED_VIDEO_CALL = 'GUEST_JOINED_VIDEO_CALL',
    VIDEO_CALL_INVITATION_SENT = 'VIDEO_CALL_INVITATION_SENT',
}

export interface ToasterMessage {
    id: string;
    type: ToasterMessageType;
    content: { [key: string]: any };
    duration?: number;
}

// Device Update Message
export interface ToasterDeviceUpdateMessage extends ToasterMessage {
    content: ToasterDeviceUpdateMessageContent;
}

export interface ToasterDeviceUpdateMessageContent {
    siteId: string;
    deviceId: string;
    deviceName: string;
    userSiteName: string;
    stateLabel: string;
    isUpdateValid: boolean;
    isQueued?: boolean;
    newValue?: any;
}

// Access Granted Message
export interface ToasterAccessGrantedMessage extends ToasterMessage {
    content: ToasterAccessGrantedMessageContent;
}

export interface ToasterAccessGrantedMessageContent {
    siteId: string;
    userSiteName: string;
    accessGrantedTime: number;
    intervention: Intervention;
}

// Servego Granted Message
export interface ToasterServegoChangedMessage extends ToasterMessage {
    content: ToasterServegoChangedMessageContent;
}

export interface ToasterServegoChangedMessageContent {
    siteId: string;
    hasAccess: boolean;
    userSiteName: string;
}

// Intervention request sent Message
export interface ToasterInterventionRequestSentMessage extends ToasterMessage {
    content: ToasterMailSentMessageContent;
}

export interface ToasterMailSentMessageContent {
    siteId: string;
    userSiteName: string;
    message: string;
}

export interface ToasterDeviceStateRefreshFailedMessage extends ToasterMessage {
    content: ToasterDeviceStateRefreshFailedMessageContent;
}

export interface ToasterDeviceStateRefreshFailedMessageContent {
    siteId: string;
    deviceId: string;
    deviceName: string;
    userSiteName: string;
    stateLabel: string;
    isQueued?: boolean;
}

export interface ToasterSiteDeviceErrorMessage extends ToasterMessage {
    content: ToasterSiteDeviceErrorMessageContent;
}

export interface ToasterSiteDeviceErrorMessageContent {
    siteId?: string;
    deviceId?: string;
    deviceName?: string;
    sectionLabel?: string;
    userSiteName?: string;
    errorType?: string;
    errorLabel: string;
}

export interface ToasterSuccessMessageContent {
    successMessage: string;
    title: string;
}

export interface ToasterSuccessMessage extends ToasterMessage {
    content: ToasterSuccessMessageContent;
}

export interface ToasterNewMailReceivedMessageContent {
    title: string;
    message: string;
}

export interface ToasterNewMailReceivedMessage extends ToasterMessage {
    content: ToasterNewMailReceivedMessageContent;
}

export interface ToasterJoinVideoCallMessage extends ToasterMessage {
    content: ToasterJoinVideoCallMessageContent;
}

export interface ToasterJoinVideoCallMessageContent {
    siteId: SiteId;
    userName: string;
}

export interface ToasterVideoCallInvitationSentMessage extends ToasterMessage {
    content: ToasterMailSentMessageContent;
}
